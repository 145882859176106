<template>
  <mi-btn-dropdown
    v-model="dropdownOpen"
    flat
    class="filter-btn"
    label="Filter"
    icon-right="filter"
    no-icon-animation
  >
    <div class="filter-dropdown">
      <span class="text-family-condensed filter-text text-bold self-start"> Options Display</span>
      <mi-list v-for="opt in filterTypes" :key="opt.name">
        <mi-list-item v-ripple clickable @click="handleOptionClick(opt.name)">
          <mi-list-item-section>
            <mi-list-item-label :class="{ 'text-red': appliedFilters === opt.name }">
              {{ opt.title }}
            </mi-list-item-label>
          </mi-list-item-section>
          <mi-list-item-section v-if="appliedFilters === opt.name" side>
            <mi-icon name="check" size="16px" color="red"></mi-icon>
          </mi-list-item-section>
        </mi-list-item>
      </mi-list>
      <span class="text-family-condensed filter-text__sorting text-bold self-start"> Choices Sorting</span>
      <mi-switch
        v-model="validOnesFirst"
        label=" Valid ones first "
        @click="handleValidOnesFirst()"
      >
      </mi-switch>
    </div>
  </mi-btn-dropdown>
</template>

<script>
  import { ref } from 'vue'
  import MiList from '../../../../packages/@mi-library/MiList/MiList.vue'
  import MiListItemLabel from '../../../../packages/@mi-library/MiList/MiListItemLabel.vue'
  import MiBtnDropdown from '../../../../packages/@mi-library/MiBtnDropdown/MiBtnDropdown.vue'
  import MiIcon from '../../../../packages/@mi-library/MiIcon/MiIcon.vue'
  import MiSwitch from '../../../../packages/@mi-library/MiSwitch/MiSwitch.vue'

  export default {
    name: 'ConfigurationOptionsFilter',
    components: { MiSwitch, MiIcon, MiBtnDropdown, MiListItemLabel, MiList },
    props: {
      filterTypes: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        required: true
      }
    },
    emits: ['update:model-value', 'update:valid-first'],
    setup(props, { emit }) {
      const appliedFilters = ref('displayAll')
      const validOnesFirst = ref(false)
      const dropdownOpen = ref(false)

      const closeDropdown = () => {
        setTimeout(() => {
          dropdownOpen.value = false
        }, 500)
      }

      const handleOptionClick = (filterName = '') => {
        appliedFilters.value = filterName
        emit('update:model-value', filterName)
        closeDropdown()
      }

      const handleValidOnesFirst = () => {
        emit('update:valid-first')
        closeDropdown()
      }

      return {
        appliedFilters,
        validOnesFirst,
        handleOptionClick,
        dropdownOpen,
        handleValidOnesFirst
      }
    }
  }
</script>

<style lang="scss" scoped>
  // stylelint-disable declaration-no-important
  .q-btn {
    height: 32px !important;
    width: 77px;
    font-size: 14px !important;
  }

  .filter-dropdown {
    padding: 16px;
    width: 255px;

    .q-item__label {
      font-size: 18px;
    }

    .filter-text {
      font-size: 12px;
    }

    .q-toggle {
      padding-top: 8px;
      font-size: 16px !important;
    }
  }

  .text-red {
    color: $mi-red-500;
  }
</style>
