<template>
  <mi-expansion-item
    :model-value="isOptionExpanded"
    :disable="areChoicesLoading"
    :option-id="option.id2"
    class="configuration-option"
    group="configurationOptions"
    no-expand-icon
    popup
    @after-hide="choices = []"
    @update:model-value="getOptionChoices"
  >
    <template #header>
      <option-header
        :has-locked-choices="!!lockedChoicesIds.length"
        :has-selected-choices="!!selectedChoicesIds.length"
        :loading="areChoicesLoading"
        :option="option"
      ></option-header>
    </template>

    <mi-inner-loading :showing="areChoicesSorting">
      <div class="choices-loading">
        <mi-spinner size="24px"></mi-spinner>
      </div>
    </mi-inner-loading>

    <mi-virtual-scroll
      v-if="choices.length"
      ref="miVirtualScroll"
      :items="sortedChoices"
      virtual-scroll-item-size="46"
      virtual-scroll-slice-size="20"
      separator
    >
      <template #default="{ item }">
        <choice-item
          :key="item.id"
          :choice="item"
          :is-locked="lockedChoicesIds.includes(item.id)"
          :is-selected="selectedChoicesIds.includes(item.id)"
          :is-valid="validChoicesIds.includes(item.id)"
          :option-id="option.id"
          @child-mounted="childMounted"
        ></choice-item>
      </template>
    </mi-virtual-scroll>
  </mi-expansion-item>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { getOption } from '@/api'
  import {
    MiExpansionItem,
    MiVirtualScroll
  } from '@/packages/@mi-library'

  import { poll } from '@/api/polling'
  import { PM_TYPE } from '@/constants'
  import ChoiceItem from './ConfigurationOptionsItemChoice.vue'
  import OptionHeader from './ConfigurationOptionsItemHeader.vue'

  const { mapGetters, mapActions, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationOptionsItem',
    components: {
      ChoiceItem,
      OptionHeader,
      MiExpansionItem,
      MiVirtualScroll
    },
    props: {
      option: {
        type: Object,
        required: true
      },
      validFirst: {
        type: Boolean,
        default: false
      }
    },
    emits: ['target-loaded-choices'],
    data: () => ({
      areChoicesLoading: false,
      areChoicesSorting: false,
      isOptionExpanded: false,
      choices: [],
      choicesElemLoaded: 0,
      sortedChoices: []
    }),
    computed: {
      ...mapGetters({ getSelectedChoicesIds: 'selectedChoicesIds' }),
      ...mapState({
        choicesState: 'choices',
        currentConfiguration: 'currentConfiguration',
        lockedChoicesIds({ lockedChoicesIds } = {}) {
          return lockedChoicesIds.get(this.option.id) || []
        },
        validChoicesIds({ possibleCombinationChoicesIds } = {}) {
          return possibleCombinationChoicesIds.valid?.get(this.option.id) || []
        }
      }),
      selectedChoicesIds() {
        return this.getSelectedChoicesIds(this.option.id) || []
      }
    },
    watch: {
      async validFirst() {
        if (this.isOptionExpanded) {
          this.areChoicesSorting = true
          this.sortChoicesByValidFirst()
          setTimeout(() => {
            this.areChoicesSorting = false
          }, 500)
        }
      }
    },
    beforeUnmount() {
      this.clearPoll?.()
    },
    methods: {
      ...mapActions(['getPossibleCombinationsAsync']),

      childMounted(e) {
        if (!e) return
        this.choicesElemLoaded += 1
        if (this.choices.length !== this.choicesElemLoaded) return
        setTimeout(() => {
          this.choicesElemLoaded = 0
          this.$emit('target-loaded-choices', true)
        }, '500')
      },
      async getOptionChoices(showChoices = false) {
        if (showChoices) {
          this.areChoicesLoading = true
          try {
            const [{ choices = [] } = {}] = await Promise.all([
              getOption({
                id: this.option.id,
                pmIdentifier: this.currentConfiguration.productModel?.encodedBusinessName,
                pmType: PM_TYPE.PRODUCT_MODEL,
                choiceSort: 'name,asc'
              }),
              await this.getOptionPossibleCombinationsAsync([this.option.id])
            ])
            this.choices = Object.freeze(choices)
            this.sortChoicesByValidFirst()
          }
          finally {
            this.areChoicesLoading = false
          }
        }

        this.$nextTick(() => {
          this.isOptionExpanded = showChoices
        })
      },
      sortChoicesByValidFirst() {
        this.sortedChoices = this.validFirst
          ? [...this.choices].sort((a, b) => {
            const aPriority = this.validChoicesIds.includes(a.id) ? 0 : 1
            const bPriority = this.validChoicesIds.includes(b.id) ? 0 : 1
            return aPriority - bPriority
          })
          : this.choices
      },
      async getOptionPossibleCombinationsAsync() {
        const { clearPoll } = poll()
        this.clearPoll = clearPoll

        await this.getPossibleCombinationsAsync([this.option.id])
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-option {
    width: 100%;

    .choices-loading {
      opacity: 1;
    }

    &.q-expansion-item--expanded ::v-deep(.mi-expansion-item__header) {
      position: sticky;
      top: 0;
      background-color: $mi-white;
      z-index: 1;
    }

    > ::v-deep(.q-expansion-item__container) {
      border: 0;
    }

    ::v-deep(.mi-expansion-item__header),
    ::v-deep(.q-expansion-item__content) {
      border-style: solid;
      border-color: $mi-anthracite-400;
    }

    ::v-deep(.mi-expansion-item__header) {
      border-width: 2px;
    }

    ::v-deep(.q-expansion-item__content) {
      border-width: 0 2px 2px;
    }

    &.q-expansion-item--collapsed:not(:first-child) ::v-deep(.mi-expansion-item__header) {
      border-top-width: 0;
    }

    &.q-expansion-item--expanded + .q-expansion-item--collapsed ::v-deep(.mi-expansion-item__header) {
      border-top-width: 2px;
    }
  }
</style>
