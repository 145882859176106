<template>
  <mi-dialog
    :model-value="modelValue"
    class="validation-retry-analysis-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <img src="@/assets/images/pac/retry-analysis-icon.svg" alt="" />
    <h3>Deeper analysis required</h3>
    <p>
      CoRE has reached its limit, and not all cases were analysed.
      To obtain accurate results, additional analysis is required, which may take longer.
      Do you want to retry with extra analysis?
    </p>
    <template #actions>
      <mi-btn v-close-popup class="cancel-analysis"> Cancel analysis </mi-btn>
      <mi-btn class="retry-analysis" @click="emitRetryAnalysis()">
        Yes, retry analysis
      </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  export default {
    name: 'ConfigurationInfoValidationRetryAnalysisDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      }
    },
    emits: ['update:model-value', 'retry-analysis'],
    setup(props, { emit }) {
      const emitUpdateModelValue = value => {
        emit('update:model-value', value)
      }

      const emitRetryAnalysis = () => {
        emit('retry-analysis')
        emitUpdateModelValue(false)
      }

      return {
        emitUpdateModelValue,
        emitRetryAnalysis
      }
    }
  }
</script>

<style lang="scss">
  // stylelint-disable declaration-no-important
  .validation-retry-analysis-dialog {
    .q-dialog__inner > .mi-card {
      width: 352px;
      max-width: 352px;
      height: 504px;
      max-height: 504px;
      text-align: center;
    }

    .q-card__section:not(:first-child) {
      padding: 12px 16px 28px;
    }

    h3 {
      margin: 16px 0;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .validation-retry-analysis-dialog {
      .q-dialog__inner > .mi-card {
        width: 352px;
        max-width: 352px;
      }
    }
  }

  @media (min-width: 600px) {
    .validation-retry-analysis-dialog {
      .q-dialog__inner--minimized > div {
        max-width: 352px;
        min-height: 504px;
      }
    }
  }

  .cancel-analysis {
    margin-top: 12px;
    width: 156px;
    height: 42px;
    color: #303c49 !important;
    background-color: #d3d3d3 !important;
  }

  .retry-analysis {
    margin-top: 12px;
    width: 156px;
    height: 42px;
  }
</style>
