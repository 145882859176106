<template>
  <info-card
    :caption="`${ validation.results.length } result(s) shown`"
    :items="validation.results"
    :loading="validation.isInProgress"
    :initial-state="initialState"
    :is-valid-validation="isValidValidation"
    loading-label="Validating configuration. Please wait ..."
  >
    <template #removeAllAction>
      <mi-btn
        v-if="validation.results.length"
        data-testid="remove-all-btn"
        flat
        @click="openDeleteChoiceDialog"
      >
        Remove all
      </mi-btn>
    </template>
    <!-- Validation results item -->
    <template #item="{ item }">
      <mi-list-item
        :key="item.id"
        class="validation-item bg-white"
        dense
        :clickable="!optionsAreLoading"
        @click="!isDeleteChoiceDialogShown ? $emit('click:validation-item', { ...item }) : ''"
      >
        <!-- Content -->
        <mi-list-item-section>
          <mi-tooltip max-width="330px">{{ item.choiceName }}</mi-tooltip>
          <mi-list-item-label>
            {{ item.optionId2 }} - {{ item.choiceId2 }} - {{ getTruncatedString(item.choiceName) }}
          </mi-list-item-label>
        </mi-list-item-section>

        <!-- Delete button -->
        <mi-list-item-section side>
          <mi-btn
            class="validation-item__remove-btn"
            icon="close"
            icon-size="12px"
            icon-type
            dense
            fab
            flat
            @click="openDeleteChoiceDialog(item)"
          ></mi-btn>
        </mi-list-item-section>
      </mi-list-item>
    </template>

    <!-- Validate button -->
    <template #actions>
      <mi-btn color="accent" @click="handleValidateCurrentConfiguration(false)"> Validate </mi-btn>
    </template>
  </info-card>

  <!-- Delete invalid choice dialog -->
  <delete-choice-dialog
    v-model="isDeleteChoiceDialogShown"
    :choice="choiceToUnselect"
    :remove-all-choices="removeAllChoices"
    @unselect:choice="handleChoiceUnselectEvent"
  ></delete-choice-dialog>

  <!-- Retry analysis dialog -->
  <retry-analysis-dialog
    v-model="isRetryAnalysisDialogShown"
    @retry-analysis="handleValidateCurrentConfiguration(true)"
  >
  </retry-analysis-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { PAC_VALIDATION_REQUESTED, PAC_UNSELECT_VALIDATION } from '@/utils/analytics/constants'
  import { poll } from '@/api/polling'
  import { CHANGED_CONFIGURATION, SET_VALIDATION } from '@/store/modules/pac/mutationTypes'
  import InfoCard from '@/components/pac/configuration/info/ConfigurationInfoCard.vue'
  import DeleteChoiceDialog from './ConfigurationInfoValidationUnselectDialog.vue'
  import RetryAnalysisDialog from './ConfigurationInfoValidationRetryAnalysisDialog.vue'
  import { NO_RECOVERY_SUGGESTIONS_AVAILABLE_MESSAGE } from '../../../../../constants'

  const { mapActions, mapGetters, mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationInfoValidation',
    components: {
      DeleteChoiceDialog,
      InfoCard,
      RetryAnalysisDialog
    },
    emits: ['click:validation-item'],
    data: () => ({
      isDeleteChoiceDialogShown: false,
      choiceToUnselect: {},
      clearPoll: null,
      removeAllChoices: true,
      isRetryAnalysisDialogShown: false,
      completedDeepAnalysis: false
    }),
    computed: {
      ...mapState(['validation', 'isValidValidation', 'initialState', 'changedConfiguration']),
      ...mapGetters(['optionsAreLoading']),
      configurationChanged() {
        return this.changedConfiguration
      }
    },
    watch: {
      configurationChanged() {
        this.completedDeepAnalysis = false
      }
    },
    beforeUnmount() {
      this.clearPoll?.()
    },
    methods: {
      ...mapActions(['validateCurrentConfigurationV2']),
      ...mapMutations({ SET_VALIDATION, CHANGED_CONFIGURATION }),
      getTruncatedString(string) {
        return string.split('', 30)
          .reduce((o, c) => (o.length === 29 ? `${ o }${ c }...` : `${ o }${ c }`), '')
      },
      handleChoiceUnselectEvent() {
        this.choiceToUnselect = {}
        this.removeAllChoices = true

        // Analytics
        recordAnalytics(PAC_UNSELECT_VALIDATION)

        if (this.validation.results?.length) return

        this.SET_VALIDATION({ showBadge: false, initialState: true })
      },
      openDeleteChoiceDialog({ optionId = '', choiceId = '', choiceId2 = '' } = {}) {
        if (optionId && choiceId) {
          this.choiceToUnselect = { optionId, choiceId, choiceId2 }
          this.removeAllChoices = false
        }
        this.isDeleteChoiceDialogShown = true
      },
      async getValidateConfiguration(isRetryAnalysis) {
        const { executePoll, clearPoll } = poll()
        this.clearPoll = clearPoll
        return this.validateCurrentConfigurationV2({ executePoll, isRetryAnalysis })
      },
      async handleValidateCurrentConfiguration(isRetryAnalysis) {
        if (isRetryAnalysis) {
          this.completedDeepAnalysis = true
        }

        const result = await this.getValidateConfiguration(isRetryAnalysis)

        if (result?.errorMessage?.toString() === NO_RECOVERY_SUGGESTIONS_AVAILABLE_MESSAGE
          && !this.completedDeepAnalysis) {
          this.isRetryAnalysisDialogShown = true
          return
        }

        if (result?.status !== 'error') {
          this.completedDeepAnalysis = false
          return
        }

        const errorMessage = result.errorMessage !== 'Async request failed' ? result.errorMessage
          : 'An unexpected error occurred when validating the configuration.'
        notify({
          title: 'Validation error',
          content: errorMessage,
          type: 'negative'
        })
        recordAnalytics(PAC_VALIDATION_REQUESTED)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validation-item__remove-btn.mi-btn.q-btn--fab.q-btn--dense {
    padding: .25rem;
    margin-right: -.25rem;
  }
</style>
