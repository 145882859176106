<template>
  <!-- Lock icon -->
  <mi-list-item-section avatar>
    <mi-icon
      :color="hasLockedChoices ? 'primary' : 'secondary'"
      :name="hasLockedChoices ? 'lock-circle' : 'lock-open-circle'"
      size="16px"
    ></mi-icon>
  </mi-list-item-section>

  <mi-list-item-section :class="hasSelectedChoices ? 'col-8' : 'col-grow'">
    <mi-list-item-label class="configuration-option-header__label flex items-center">
      <!-- Name and id2 -->
      <span class="ellipsis-2-lines text-weight-medium"> ({{ option.id2 }}) {{ option.name }} </span>

      <!-- Category -->
      <mi-badge
        v-if="option.categoryName"
        :label="option.categoryName"
        :title="option.categoryName"
        class="configuration-option-header__category inline-block ellipsis"
        color="primary"
        outline
      ></mi-badge>
    </mi-list-item-label>
  </mi-list-item-section>

  <!-- Selected choices -->
  <selected-choices
    v-show="hasSelectedChoices"
    :option-id="option.id"
    class="q-ml-auto"
  ></selected-choices>

  <!-- Loading state -->
  <mi-inner-loading :showing="loading">
    <div class="flex justify-end full-width q-px-lg">
      <mi-spinner size="24px"></mi-spinner>
    </div>
  </mi-inner-loading>
</template>

<script>
  import SelectedChoices from './ConfigurationOptionsItemSelectedChoices.vue'

  export default {
    name: 'ConfigurationOptionsItemHeader',
    components: { SelectedChoices },
    props: {
      hasSelectedChoices: {
        type: Boolean,
        required: false,
        default: false
      },
      hasLockedChoices: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      option: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-option-header__label {
    gap: .5rem 1rem;
  }

  .configuration-option-header__category {
    max-width: 200px;
  }
</style>
